import React from 'react';

import Grid, { GridRow, GridCol } from '../atoms/grid';
import { Devider } from '../atoms/devider';
import { FormComponent } from '../molecules/form';

export const ContactForm = ({ elements, stateData, setState, ...props }) => {
  return (
    <Grid gap={20} noPadding={true} offset={true}>
      <GridRow direction="row">
        <GridCol colSize={7}>
          <Grid noPadding={true}>
            <GridRow direction="column">
              <GridCol>
                <FormComponent stateData={stateData} name={'firstName'} setState={setState} />
              </GridCol>
            </GridRow>
          </Grid>
        </GridCol>
        <GridCol colSize={5} stretched={true}>
          <Grid>
            <GridRow direction="column">
              <GridCol>
                <FormComponent stateData={stateData} name={'lastName'} setState={setState} />
              </GridCol>
              <Devider margin={10} />
              <GridCol>
                <FormComponent stateData={stateData} name={'gender'} setState={setState} />
              </GridCol>
            </GridRow>
            <GridRow direction="column">
              <GridCol>
                <FormComponent stateData={stateData} name={'message'} setState={setState} />
              </GridCol>
              <Devider margin={10} />
              <GridCol>
                <FormComponent stateData={stateData} name={'agree'} setState={setState} />
              </GridCol>
            </GridRow>
          </Grid>
        </GridCol>
      </GridRow>
    </Grid>
  );
};