import React from 'react';
import axios from 'axios';

import { getComponent, updateState } from '../../../utils';

export default ({ data = {}, ...props }) => {

  const onSubmit = async (e) => {
    e.preventDefault();
    await axios.post('/', data);
  };

  return (
    <form onSubmit={onSubmit}>
      {
        props.children
      }
    </form>
  );
};

export const FormComponent = ({ stateData, name, setState, ...props }) => {
  const element = stateData.elements.find(element => element.name === name);
  const { Component, CompProps } = element && getComponent(element);
  if (!Component) return false;
  let value = stateData[element.name];
  return <Component
    {...CompProps}
    name={element.name}
    data={{ ...element }}
    value={value}
    onChange={(e) => updateState(e, setState)}
    onBlur={(e) => e}
  />;
};

export const FormComponents = ({ stateData, setState, ...props }) => stateData.elements.map((el, index) => {
  const { Component, CompProps } = el && getComponent(el);
  if (!Component) return false;
  let value = stateData[stateData.elements[index].name];
  return <Component
    {...CompProps}
    name={el.name}
    key={index}
    data={{ ...el }}
    value={value}
    onChange={(e) => updateState(e, setState)}
    onBlur={(e) => e}
  />;
});

