import React, { useState, useEffect } from 'react';

import Form from '../molecules/form';
import { setInitState, getFormFields } from '../../../utils';
import * as FormLayouts from '../templates/layout-forms';
import { FormComponents } from '../molecules/form';


export default ({ custom = false, ...props }) => {
  const initData = { ...getFormFields(props.elements) };
  const [stateData, setState] = useState({
    ...props,
    ...initData,
    elements: [
      ...props.elements
    ]
  });

  useEffect(() => {
    setInitState(stateData, setState);
    // eslint-disable-next-lines
  },[]);

  const CustomLayout = custom ? FormLayouts[custom] : null;

  return (
    <>
      <Form data={stateData}>
        {
          !custom ?
            <FormComponents stateData={stateData} setState={setState} />
            :
            <CustomLayout {...props} stateData={stateData} setState={setState} />
        }
        <button type="submit">submit</button>
      </Form>
    </>
  );
};
