import React from 'react';

import '../../../styles/index.scss';

import SEO from '../atoms/seo';
import HOC from '../organisms/root';
import Form from '../organisms/form';

import { getPageData } from '../../../utils';

const Layout = ({ pageContext, ...props }) => {
  // const globalData = props.pageContext.data;
  if (!pageContext) return <></>;

  const { data, forms } = pageContext && getPageData(pageContext.data, pageContext.type, pageContext.slug);

  return (
    <HOC>
      <SEO {...data.seo} />
      {
        forms &&
        <Form custom={forms.contactForm.formLayout || undefined} elements={forms.contactForm.elements} />
      }
    </HOC>
  );
};

export default Layout;
